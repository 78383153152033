<template>
  <component :is="getActiveComponent"></component>
</template>

<script>

import DesktopLogin from "./Desktop";
import ResponsiveLogin from "./Responsive";

export default {
  name: 'LoginPage',
  components: {ResponsiveLogin, DesktopLogin},
  computed: {
    getActiveComponent() {
      return this.$device.mobile ? 'ResponsiveLogin' : 'DesktopLogin'
    }
  }
}
</script>
