<template>
  <div class="page-job-details-desktop">
    <div
        v-if="job.loading"
        class="pa-16 d-flex align-center justify-center">
      <v-progress-circular
          color="main"
          indeterminate
      />
    </div>
    <v-card
        v-else
        height="100%">
      <v-card-title>
        <PageTitle
            class="w-100"
            icon="mdi-file-document-outline"
            title="جزییات آگهی کاریابی"
        >
          <div>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    @click="report.modal=true"
                    v-bind="attrs"
                    v-on="on"
                    class="ml-3"
                    icon
                    text
                    color="warning"
                >
                  <v-icon>
                    mdi-information-outline
                  </v-icon>
                </v-btn>
              </template>
              ثبت گزارش
            </v-tooltip>
            <v-btn
                @click="$router.back()"
                elevation="0"
                outlined
                color="main"
            >
              بازگشت
              <v-icon class="mr-2">
                mdi-arrow-left
              </v-icon>
            </v-btn>
          </div>
        </PageTitle>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col :cols="12">
            <v-carousel
                hide-delimiters
                height="350"
            >
              <v-carousel-item
                  class="bg-grey"
                  v-for="(item, index) in job.images"
                  :key="index"
              >
                <v-img
                    contain
                    lazy-src="/img/placeholder.png"
                    :src="item"/>
              </v-carousel-item>
            </v-carousel>
          </v-col>

          <v-col :cols="6">
            <div class="font-weight-bold text-body-1 mb-8 ">
              جزییات آگهی
              <v-divider/>
            </div>
            <div class="mb-6">
              <v-icon color="success">
                mdi-check-circle
              </v-icon>
              <span class="job-name">
                  عنوان شغل مورد نظر :
                </span>
              <span class="job-value">
                 {{ job.name }}
                </span>
            </div>
            <div class="mb-6">
              <v-icon color="success">
                mdi-check-circle
              </v-icon>
              <span class="job-name">
                  شیفت کاری :
                </span>
              <span class="job-value">
               {{ job.workingShift }}
                </span>
            </div>
            <div class="mb-6">
              <v-icon color="success">
                mdi-check-circle
              </v-icon>
              <span class="job-name">
                  ساعات کاری :
                </span>
              <span class="job-value">
                  {{ job.startTime }}
                تا
                {{ job.endTime }}
                </span>
            </div>
            <div class="mb-6">
              <v-icon color="success">
                mdi-check-circle
              </v-icon>
              <span class="job-name">
                  منطقه مورد نظر :
                </span>
              <span class="job-value">
                  {{ job.location }}
                </span>
            </div>
            <div class="mb-6">
              <v-icon color="success">
                mdi-check-circle
              </v-icon>
              <span class="job-name">
                  تخصص :
                </span>
              <span class="job-value">
                  {{ job.expertise }}
                </span>
            </div>
          </v-col>
          <v-col :cols="6">
            <img src="/img/job.jpg" alt="">
          </v-col>
        
        </v-row>
      </v-card-text>
    </v-card>

        <v-dialog
            max-width="500"
            v-model="report.modal">
          <v-card>
            <v-card-title class="font-weight-bold text-body-1">
              <v-icon size="25" class="ml-2">
                mdi-information-outline
              </v-icon>
              گزارش مشکل در مورد این آگهی
            </v-card-title>
            <v-card-text>
              <v-list dense>
                <v-list-item>
                  <template>
                    <v-list-item-action>
                      <v-checkbox
                          v-model="report.washired"
                          dense
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                       استخدام شده
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
                <v-list-item>
                  <template>
                    <v-list-item-action>
                      <v-checkbox
                          v-model="report.notresponsive"
                          dense></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        پاشخگو نیست
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
                <v-list-item>
                  <template>
                    <v-list-item-action>
                      <v-checkbox
                          v-model="report.wrongcontactnumber"
                          dense></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                      آگهی اشتباه است
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list>
            </v-card-text>
            <v-card-actions class="d-flex align-center justify-space-between">
              <v-btn

                  @click="report.modal=false"
                  elevation="0"
                  text
                  color="main">
                بستن
              </v-btn>

              <v-btn
                  :disabled="!checkButton"
                  :loading="report.loading"
                  @click="submitReport"
                  class="white--text"
                  color="main"
              >
                ثبت گزارش
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
  </div>
</template>

<script>

import PageTitle from "@/components/PageTitle"
import {find,submitReport} from "@Newfiling/module-job/src/api"
import {AppConfig} from "@Newfiling/Services";


export default {
  name: 'DesktopJobDetails',

  components: {
    PageTitle
  },

  data() {
    return {
      report: {
        modal: false,
        loading: false,
        fileid: null,
        washired: false,
        notresponsive: false,
        wrongcontactnumber: false
      },
      locations: [],
      jobTypes: [],
      shiftItems: [
        {
          id: 1,
          name: 'صبح تا ظهر',
        },
        {
          id: 2,
          name: 'عصر تا شب',
        },
        {
          id: 3,
          name: 'تمام',
        },
      ],
      job: {
        loading: false,
        images: [],
        name: '',
        workingShift: '',
        startTime: '',
        endTime: '',
        location: '',
        expertise: '',
      }
    }
  },

  mounted() {
    this.$nextTick(async () => {
      const appConfigs = (await AppConfig) || {}
      this.jobTypes = appConfigs.joblist.map(item => {
        return {
          id: item.Id,
          name: item.Jobname,
        }
      })
      this.locations = appConfigs.neighborhoods.map(item => {
        return {
          id: item.Id,
          name: item.NeighborhoodName,
        }
      })
      await this.fetchJob(this.$route.params.id);
    })
  },

  computed:{
    checkButton(){
      return Boolean(this.report.notresponsive || this.report.washired || this.report.wrongcontactnumber);
    },
  },

  methods: {
    async fetchJob(id) {
      this.job.loading = true;
      try {
        const res = (await find(id))?.data?.list || {};
        console.log({res})
        this.job = {
          ...this.job,
          id: res.id,
          images: [res.image1, res.image2, res.image3, res.image4].filter(item => item),
          name: this.jobTypes.find(item => item.id === res.jobcode)?.name,
          workingShift: this.shiftItems.find(i => i.id === res.shiftwork)?.name,
          startTime: res.startinghours,
          endTime: res.endtime,
          location: this.locations.find(item => item.id === res.locationsid)?.name,
          expertise: res.expertise,
        }
      } catch (e) {
        console.log(e)
      }
      this.job.loading = false;
    },

    async submitReport() {
      this.report.loading = true;
      try {
        await submitReport({
          ...this.report,
          fileid:this.$route.params.id
        });
        this.$toast.success('گزارش شما با موفقیت ثبت شد.');
        this.report = {
          ...this.report,
          model: false,
          fileid: null,
          washired: false,
          notresponsive: false,
          wrongcontactnumber: false
        }
        this.report.modal = false;
      } catch (e) {
        console.log(e)
      }
      this.report.loading = false;
    }
  }

}
</script>

<style scoped>
.job-name {
  color: rgba(0, 0, 0, 0.7);
  font-weight: bold;
  font-size: 14px;
}

.job-value {
  color: #000;
  font-weight: bold;
  font-size: 16px;
}
</style>
